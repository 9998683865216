import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import GitHubIcon from '@material-ui/icons/GitHub'
import Avatar from '../components/avatar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      position: 'absolute',
      bottom: 0,
      left: '-195px',
    },
  },
}))

const Panel = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(12),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 350,
    }
  }
}))(Paper)

const HomePage = () => {
  const classes = useStyles()
  const year = new Date().getFullYear()
  return (
    <Layout>
      <SEO title=''/>
      <Grid container direction='column' alignItems='center' justify='center'>
        <Grid item xs={12} md={10}>
          <Panel>
            <Avatar className={classes.avatar}/>
            <Typography variant='body1' component='p'>
              Hello, Pablo Tejada here. Welcome to my own little space in the web. I am a passionate self thought full
              stack Web
              Developer. Coding has been part of my day to day life for the past {year - 2012} years. I spent my first
              two years working on open source projects and both learning a lot from the
              {' '}<Link href="https://stackoverflow.com/users/209067/pablo" target="_blank">Stackoverflow</Link> {' '}
              community as well as helping other developers. I started my professional career as a Web Developer back
              in 2014 and to this date I am still working for the same company.
            </Typography>
            <br/>
            <Typography variant='body1' component='p'>
              I mainly focus on building PHP backends and dynamic user interfaces with HTML, CSS and JavaScript. When I
              am not coding I am either spending time with the family or exploring my passion for Photography.
            </Typography>
            <br/>
            <Typography variant='body1' component='p'>
              Check out my open source projects and contributions in
              {' '}<Link href="https://github.com/ptejada" target="_blank">
              <GitHubIcon fontSize='small' htmlColor='#000' style={{ verticalAlign: 'top' }}/> {' '}
              Github
            </Link>.
              You can find some of my photography work in
              {' '}<Link href="https://www.flickr.com/photos/ptejada/" target="_blank">Flickr</Link> and
              {' '}<Link href="https://500px.com/ptejada" target="_blank">500px</Link>.
              I am not very active on social media but if you want to get contact DM
              {' '}<Link href="https://twitter.com/messages/compose?recipient_id=177614048" target="_blank">
              @PabloSite
            </Link>.
            </Typography>
          </Panel>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default HomePage
